<div>
    <slot name="modals"></slot>
    <slot></slot>
</div>

<style lang="scss">
  div {
    position: relative;
    width: 100%;
    height: 100%;
  }
</style>